.page-container {
  width: 100%;
  min-height: 100vh !important;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 !important;
  flex-direction: column;
  position: relative;
}
.login-page {
  .main-footer {
    margin: 0;
    position: absolute;
    bottom: 0;
    width: 100%;
  }
  .login-form-container {
    padding: 20px;
    width: 360px;
    margin: 0 auto;
  }
  .login-form__submit {
    width: 100%;
  }
}
