.form-group {
  width: 100%;
}
iframe {
  display: none;
}

.wrapper {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}
#codeDescription {
  max-width: 500px !important;
}


.content-wrapper {
  flex: 1;
  @media(max-width: 1024px) {
    overflow-x: scroll;
  }
}

.user-panel .img-circle {
  min-height: 30px !important;
}

.table-action-button {
  margin: 5px;
}

.table-content-container {
  overflow-x: auto;
}

.ck-editor__editable {
  min-height: 366px;
}


.smartTable-table, .dataTable {
  td {
    vertical-align: inherit !important;
  }

  td p {
    margin: 0;
  }

  tr th:last-child, tr th:first-child {
    pointer-events: none;

    &:after {
      display: none !important;
    }
  }

  tfoot {
    display: none;
  }

  .smartTable-column-header-sort {
    padding: 5px 9.7px;
    margin-left: -9.7px;

    &:hover {
      opacity: 0.7;
    }
  }
}


div[class^="Snackbar_snackbar-wrapper"] {
  z-index: 1100;
}


.tableCouponsWrap {
  width: 100%;
  th.sorting, tbody td{
    box-sizing: border-box !important;
  }
  th.sorting:nth-child(2), tbody td:nth-child(2){
    max-width: 94px !important;
    min-width: 94px !important;
  }
  th.sorting:nth-child(3), tbody td:nth-child(3){
    max-width: 141px !important;
    min-width: 141px !important;
  }
  th.sorting:nth-child(5), tbody td:nth-child(5), th.sorting:nth-child(9), tbody td:nth-child(9), th.sorting:nth-child(8), tbody td:nth-child(8), th.sorting:nth-child(10), tbody td:nth-child(10){
    max-width: 121px !important;
    min-width: 121px !important;
    overflow: hidden;
  }
  th.sorting:nth-child(6), tbody td:nth-child(6){
    max-width: 103px !important;
    min-width: 103px !important;
  }
  th.sorting:nth-child(7), tbody td:nth-child(7){
    max-width: 100px !important;
    min-width: 100px !important;
  }
  th.sorting:nth-child(12), tbody td:nth-child(12), th.sorting:nth-child(12), tbody td:nth-child(12){
    max-width: 112px !important;
    min-width: 112px !important;
  }
  th.sorting:nth-child(13), tbody td:nth-child(13){
    max-width: 96px !important;
    min-width: 96px !important;
  }

  thead tr {
    th.sorting {
      cursor: default;
      pointer-events: none;
      &:after {
        display: none;
      }
    }

    th.sorting:nth-child(12){
      padding: 8px;
      text-align: center;
    }
    th.sorting:nth-child(2),
    th.sorting:nth-child(3),
    th.sorting:nth-child(6),
    th.sorting:nth-child(7),
    th.sorting:nth-child(9),
    th.sorting:nth-child(11),
    th.sorting:nth-child(12) {
      cursor: pointer;
      pointer-events: auto;

      &:after {
        display: inline-block;
      }
    }
  }
}
.feedbackSpinner{
  .overlay{
    background-color: #fff;
    opacity: 0.95;
    svg{
      top: 10% !important;
    }
  }
}
.tableFeedbackWrap {
  width: 100%;


  th.sorting, tbody td{
    box-sizing: border-box !important;
  }
  th.sorting:nth-child(1), tbody td:nth-child(1){
    max-width: 42px !important;
    min-width: 42px !important;
  }
  th.sorting:nth-child(2), tbody td:nth-child(2){
    max-width: 89px !important;
    min-width: 89px !important;
    overflow: hidden;
  }
  th.sorting:nth-child(3), tbody td:nth-child(3){
    max-width: 141px !important;
    min-width: 141px !important;
    overflow: hidden;
  }
  th.sorting:nth-child(4), tbody td:nth-child(4){
    max-width: 179px !important;
    min-width: 179px !important;
    overflow: hidden;
  }
  th.sorting:nth-child(5), tbody td:nth-child(5){
    max-width: 125px !important;
    min-width: 125px !important;
    overflow: hidden;
  }
  th.sorting:nth-child(6), tbody td:nth-child(6){
    max-width: 92px !important;
    min-width: 92px !important;
    overflow: hidden;
  }
  th.sorting:nth-child(7), tbody td:nth-child(7){
    max-width: 118px !important;
    min-width: 118px !important;
    overflow: hidden;
  }
  th.sorting:nth-child(9), tbody td:nth-child(9){
    max-width: 85px !important;
    min-width: 85px !important;
    overflow: hidden;
  }
  th.sorting:nth-child(9), tbody td:nth-child(9){
    max-width: 112px !important;
    min-width: 112px !important;
    overflow: hidden;
  }

  thead tr {
    th.sorting {
      cursor: default;
      pointer-events: none;

      &:after {
        display: none;
      }
    }

    th.sorting:nth-child(2),
    th.sorting:nth-child(3),
    th.sorting:nth-child(4),
    th.sorting:nth-child(5),
    th.sorting:nth-child(6),
    th.sorting:nth-child(7),
    th.sorting:nth-child(8),
    th.sorting:nth-child(9){
      cursor: pointer;
      pointer-events: auto;

      &:after {
        display: inline-block;
      }
    }
  }
}

.tableDomainWrap {
  min-width: 1012px;
  &.changeSort{
    .row:nth-child(2) tbody{
      opacity: 0;
      pointer-events: none;
    }
  }

  th.sorting, tbody td{
    box-sizing: border-box !important;
  }
  th.sorting:nth-child(1), tbody td:nth-child(1){
    max-width: 42px !important;
    min-width: 42px !important;
  }
  th.sorting:nth-child(2), tbody td:nth-child(2){
    max-width: 154px !important;
    min-width: 154px !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  th.sorting:nth-child(3), tbody td:nth-child(3){
    max-width: 87px !important;
    min-width: 87px !important;
  }
  th.sorting:nth-child(4), tbody td:nth-child(4){
    max-width: 89px !important;
    min-width: 89px !important;
    overflow: hidden;
  }
  th.sorting:nth-child(5), tbody td:nth-child(5){
    max-width: 98px !important;
    min-width: 98px !important;
    overflow: hidden;
  }
  th.sorting:nth-child(6), tbody td:nth-child(6){
    max-width: 81px !important;
    min-width: 81px !important;
    overflow: hidden;
  }
  th.sorting:nth-child(7), tbody td:nth-child(7),th.sorting:nth-child(8), tbody td:nth-child(8){
    max-width: 112px !important;
    min-width: 112px !important;
    overflow: hidden;
  }
  th.sorting:nth-child(9), tbody td:nth-child(9){
    max-width: 96px !important;
    min-width: 96px !important;
    overflow: hidden;
  }

  thead tr {
    th.sorting {
      cursor: default;
      pointer-events: none;
      &:after {
        display: none;

      }
    }

    th.sorting:nth-child(2),
    th.sorting:nth-child(3),
    th.sorting:nth-child(7),
    th.sorting:nth-child(8){
      cursor: pointer;
      pointer-events: auto;

      &:after {
        display: inline-block;
      }
    }
  }

}
.tableCategoryWrap{
  .btn.disabled,.btn[disabled]{
    pointer-events: none;
    cursor: not-allowed;
    color: #656565
  }
  thead tr {
    th.sorting {
      cursor: default;
      pointer-events: none;

      &:after {
        display: none;
      }
    }
  }
  thead tr {
    th.sorting:nth-child(2),
    th.sorting:nth-child(3),
    th.sorting:nth-child(4),
    th.sorting:nth-child(5),
    th.sorting:nth-child(6),
    th.sorting:nth-child(7) {
      cursor: pointer;
      pointer-events: auto;

      &:after {
        display: inline-block;
      }
    }
  }
}

.edit-coupon-form {
  .SingleDatePicker .DateInput_input {
    cursor: pointer;
  }
}
.click-able-sync.disabled{
  pointer-events: none !important;
  color: #656565 !important;
  outline: none !important;
}
.notifications{
  .form-group .input-group{
    max-width: 301px !important;
  }
  .form-group:nth-child(1) .input-group{
    margin-bottom: 40px;
  }
  .form-group.has-error:nth-child(1) .input-group{
    margin-bottom: 0;
  }
  .form-group:nth-child(4) .input-group{
    max-width: 524px !important;
  }
  button.btn{
    padding: 0 47px !important;
    height: 34px;
  }
}
.datapoints-edit{
  .overlay > svg{
    top: 15%;
  }
}
.datapoints{
  .form-group.has-error{
    .select2-selection.select2-selection--single{
      border: 1px solid #e50707;
    }
    + button{
      align-self: flex-start;
    }
  }
  &__row{
    &.infoError{
      margin-bottom: -18px;
    }
    &.infoErrorURL{
      margin-bottom: 24px;
    }
    &.infoErrorSiret{
      margin-bottom: 4px;
    }
    .form-group{
      min-height:  34px;
    }
    .form-group label:empty{
      display: none;
      + div{
        margin-left: 0 !important;
        width: 100% !important;
        padding-left: 0 !important;
      }
    }
    &_half{
      .datapoints__form-group{
        position: relative;
        max-width: 301px !important;
      }
      .calendar,.form-group{
        max-width: 301px !important;
      }
    }
    &_small{
      .datapoints__form-group_popular,.calendar, .form-group{
        position: relative;
        max-width: 190px !important;
      }
    }
    &_hint{
      position: relative;
      .form-control{
        padding-right: 30px;
      }
    }
  }
  &__siretItems{
    display: flex;
    .rowSiret{
      padding-right: 0 !important;
    }
    .form-group, .input-group, input{
      max-width: 194px !important;
      width: 100% !important;
    }
  }
  &__socials{
    display: flex;
    align-items: center;
    &.infoErrorSocial{
      align-items: flex-start;
      margin-bottom: 24px;
      > div:last-child{
        align-self: center;
        margin-top: -20px;
      }
    }
    .form-group, .input-group, input{
      max-width: 301px !important;
      width: 100% !important;
    }
  }
  &__notices{
    display: flex;
  }
  &__footer-btn{
    max-width: 128px;
    width: 100% ;
  }
}
.b2bBadges {
  .form-group {
    margin-bottom: 0;
    margin-left: 0;
    label {
      display: none;
    }
    div {
      margin-left: 0;
      padding-left: 0;
      padding-right: 0;
      width: 100%;
    }
    textarea {
      max-width: 524px;
      min-height: 84px;
    }
  }
}
.google-list-box{

  & > div {
    width: 100%;

  }
  .box-footer{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    align-items: center;
    p{
      flex: 1 1 100%;
      font-weight: 400;
      font-size: 12px;
      line-height: 15px;
      text-align: center;
      color: #858585;
      max-width: 400px;
      margin: 0 auto;
    }
  }
}
// fix problem Label placeholder at B2B tab
.labelItemInput .select2-container--default .select2-search--inline .select2-search__field {
  width: 100% !important;
}


.tableGiftCardsWrap {
  width: 100%;
  th.sorting, tbody td{
    box-sizing: border-box !important;
  }

  th.sorting:nth-child(1), tbody td:nth-child(1){
    max-width: 42px !important;
    min-width: 42px !important;
  }
  th.sorting:nth-child(2), tbody td:nth-child(2){
    max-width: 222px !important;
    min-width: 222px !important;
  }
  th.sorting:nth-child(3), tbody td:nth-child(3){
    max-width: 157px !important;
    min-width: 157px !important;
  }
  th.sorting:nth-child(4), tbody td:nth-child(4){
    max-width: 219px !important;
    min-width: 219px !important;
  }
  th.sorting:nth-child(5), tbody td:nth-child(5), th.sorting:nth-child(6), tbody td:nth-child(6){
    max-width: 112px !important;
    min-width: 112px !important;
  }
  th.sorting:nth-child(7), tbody td:nth-child(7){
    max-width: 78px !important;
    min-width: 78px !important;
  }


  thead tr {
    th.sorting {
      cursor: default;
      pointer-events: none;

      &:after {
        display: none;
      }
    }

    th.sorting:nth-child(2),
    th.sorting:nth-child(4),
    th.sorting:nth-child(5),
    th.sorting:nth-child(6){
      cursor: pointer;
      pointer-events: auto;

      &:after {
        display: inline-block;
      }
    }
  }
}
.tableBoutiquesWrap{
  width: 100%;
  th.sorting, tbody td{
    box-sizing: border-box !important;
  }

  th.sorting:nth-child(1), tbody td:nth-child(1){
    max-width: 42px !important;
    min-width: 42px !important;
  }
  th.sorting:nth-child(2), tbody td:nth-child(2){
    max-width: 94px !important;
    min-width: 94px !important;
  }
  th.sorting:nth-child(3), tbody td:nth-child(3){
    max-width: 132px !important;
    min-width: 132px !important;
  }
  th.sorting:nth-child(4), tbody td:nth-child(4){
    max-width: 87px !important;
    min-width: 87px !important;
  }
  th.sorting:nth-child(5), tbody td:nth-child(5){
    max-width: 96px !important;
    min-width: 96px !important;
  }
  th.sorting:nth-child(6), tbody td:nth-child(6){
    max-width: 196px !important;
    min-width: 196px !important;
  }
  th.sorting:nth-child(7), tbody td:nth-child(7){
    max-width: 157px !important;
    min-width: 157px !important;
  }
  th.sorting:nth-child(8), tbody td:nth-child(8){
    max-width: 112px !important;
    min-width: 112px !important;
  }
  th.sorting:nth-child(9), tbody td:nth-child(9){
    max-width: 97px !important;
    min-width: 97px !important;
  }

  thead tr {
    th.sorting {
      cursor: default;
      pointer-events: none;

      &:after {
        display: none;
      }
    }
    th.sorting:nth-child(9){
      padding: 8px !important;
      text-align: center;
    }
    th.sorting:nth-child(2),
    th.sorting:nth-child(3),
    th.sorting:nth-child(8),
    th.sorting:nth-child(9){
      cursor: pointer;
      pointer-events: auto;

      &:after {
        display: inline-block;
      }
    }
  }
}

.similar-products-table{
  position: relative;
  @media screen and (max-width: 1366px) {
    overflow: hidden;
    overflow-x: scroll;
  }
  .tableBtn {
    color: #858585;
    min-width: 30px;
    height: 30px;
    padding: 0;
    border: none;
    &:hover {
      color: #858585;
      background: transparent;
      border: none;
    }
    &:focus, &:active {
      color: black
    }
  }
  tr.selected{
    background-color: #E3E3E3 !important;
    td{
      color: #3A3A3A;
    }
  }
  tr:has(.badColor) {
    > td {
      opacity: 0.5;
      &:nth-child(3) {
        background: #fff;
        opacity: 1;
        .favourites-image-zoom-wrap > img {
          opacity: 0.4;
        }
      }
    }
  }
  tr:has(input[type='checkbox']:checked) {
    opacity: 1 !important;
    > * {
      opacity: 1 !important;
    }
    .favourites-image-zoom-wrap  {
      img {
        opacity: 1 !important;
      }
    }
    .favourites-image-hidden {
      background: transparent !important;
    }
    td{
      background-color: #E3E3E3 !important;
      color: #3A3A3A;
      > .favourites-image-hidden {
        background-color: #E3E3E3 !important;
      }
    }
  }
  tr:has(.checkbox__favorite-wrap.checkbox__relevance) {
    td {
      background-color: rgb(230, 230, 255) !important;
    }
    .favourites-image-hidden {
      background-color: rgb(230, 230, 255) !important;
    }
  }
  tr:has(.applyCheckBox--notMatched),
  tr:has(.applyCheckBox--notMatched.checkbox__relevance) {
    > * {
      opacity: 0.4 !important;
    }
    .favourites-image-zoom-wrap  {
      opacity: 1 !important;
      img {
        opacity: 1 !important;
      }
    }
    .favourites-image-hidden {
      background: transparent !important;
    }
    td{
      background-color: rgba(palevioletred, 0.3) !important;
      color: #3A3A3A;
    }
  }
  tr:has(.checkbox__favorite-wrap > .applyCheckBox--Matched) {
    > * {
      opacity: 1 !important;
    }
    .favourites-image-hidden {
      background: transparent !important;
    }
    .favourites-image-zoom-wrap  {
      img {
        opacity: 1 !important;
      }
    }
    td{
      background-color: rgba(forestgreen, 0.2) !important;
      color: #3A3A3A;
    }
  }
  tr:has(.checkbox__favorite--unchecked) {
    > * {
      opacity: 0.4;
    }
  }
  &:before{
    content: '';
    position: absolute;
    top: -1px;
    left: 0;
    width: 100%;
    height: 2px;
    background: white;
  }
  thead tr {
    th.sorting {
      cursor: default;
      pointer-events: none;

      &:after {
        display: none;
      }
    }
    th.sorting:nth-child(2),
    th.sorting:nth-child(4){
      min-width: 56px;
    }
    th.sorting:nth-child(6){
      min-width: 114px;
    }
    th.sorting:nth-child(7){
      min-width: 128px;
    }
    th.sorting:nth-child(8){
      min-width: 138px;
    }
    th.sorting:nth-child(1),
    th.sorting:nth-child(2),
    th.sorting:nth-child(3),
    th.sorting:nth-child(4),
    th.sorting:nth-child(5),
    th.sorting:nth-child(6),
    th.sorting:nth-child(7),
    th.sorting:nth-child(8),
    th.sorting:nth-child(9),
    th.sorting:nth-child(10){
      text-align: center;
      padding-left: 0;
      padding-right: 0;
    }
    th.sorting:nth-child(1){
      padding-left: 10px;
    }
    th.sorting:nth-child(2),
    th.sorting:nth-child(5),
    th.sorting:nth-child(6),
    th.sorting:nth-child(7),
    th.sorting:nth-child(8),
    th.sorting:nth-child(9){
      cursor: pointer;
      pointer-events: auto;

      &:after {
        display: inline-block;
      }
    }
    th.sorting:nth-child(3) {
      &:after {
        display: none;
      }
    }
  }
  tbody tr{
    td:nth-child(1){
      padding-left: 15px;
    }
    td:nth-child(2),
    td:nth-child(3){
      p {
        display: none;
      }
    }
    td:nth-child(1),
    td:nth-child(3),
    td:nth-child(4),
    td:nth-child(7),
    td:nth-child(8),
    td:nth-child(9),
    td:nth-child(9){
      text-align: center;
    }
  }
  input[type=checkbox] {
    cursor: pointer;
  }
}

.similar-products-loading {
  margin: 32px 0;
}

.coupon-list-favorite-product{
  //overflow: hidden;
  //overflow-x: scroll;
  input[type=checkbox] {
    cursor: pointer;
  }
  thead tr {
    th.sorting {
      cursor: default;
      pointer-events: none;

      &:after {
        display: none;
      }
    }
    th.sorting:nth-child(2){
      max-width: 103px;
    }
    th.sorting:nth-child(3), th.sorting:nth-child(8){
      max-width: 130px;
    }
    th.sorting:nth-child(8){
      text-align: center;
      padding-right: 10px;
    }
    th.sorting:nth-child(4){
      max-width: 80px;
    }
    th.sorting:nth-child(5), th.sorting:nth-child(6), th.sorting:nth-child(7){
      max-width: 120px;
    }

    th.sorting:nth-child(2),
    th.sorting:nth-child(3),
    th.sorting:nth-child(4),
    th.sorting:nth-child(5){
      cursor: pointer;
      pointer-events: auto;

      &:after {
        display: inline-block;
      }
    }
  }
  tbody tr{
    td:nth-child(2){
      max-width: 103px;
    }
    td:nth-child(3){
      max-width: 130px;
    }
    td:nth-child(4){
      max-width: 80px;
    }
    td:nth-child(5), td:nth-child(6), td:nth-child(7) {
      max-width: 120px;
    }
    td:nth-child(8){
      text-align: center;
    }
  }
  .form-group{
    margin-bottom: 0;
  }
}
.coupon-delete-wrap{
  position: absolute;
  top: 0;
  border-top: 1px solid #f4f4f4;
  left: 0;
  width: 100%;
  height: 100%;
  background: white;
  display: flex;
  align-items: center;
}
.coupon-delete-wrap-done{
  position: absolute;
  top: 0;
  border-top: 1px solid #f4f4f4;
  left: 0;
  width: 100%;
  height: 100%;
  background: white;
  display: flex;
  align-items: center;
}

.coupon-delete-wrap-action{
  width: 96px;
  display: flex;
  margin-right: 10px;
  justify-content: flex-end;
  button{
    color: #858585;
    min-width: 30px;
    height: 30px;
    padding: 0;
    border: none;
    &:hover {
      color: #858585;
      background: transparent;
      border: none;
    }
    &:focus, &:active {
      color: black
    }
  }
}
.coupon-delete-text{
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #1C1C1C;
  flex: 1 1 auto;
  text-align: center;
  margin-bottom: 0;

}

.boutique-profile{
  .box-body{
    box-sizing: border-box;
    max-width: 652px;
    padding-left: 20px;
    padding-top: 20px;
    padding-bottom: 0;
    overflow-x: hidden;
  }
  .form-group{
    display: flex;
    flex-direction: column;
    label{
      padding-left: 0;
      margin-bottom: 6px;
      width: 100%;
      + div{
        width: 100%;
        padding-left: 0;
        position: relative;
        .help-block{
          position: absolute;
        }
      }
    }
  }
}

.boutique-widget{
  .box-body{
    padding-top: 20px;
    padding-left: 20px;
    padding-bottom: 20px;
  }
}
.edit-boutique{
  .overlay{
    background: rgba(255, 255, 255, 0.95);
  }
}


.coupon_loading {
  &:after {
    content: "";
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(255,255,255, 0.9);
    z-index: 3;
  }
  &.processing {
    &:before {
      content: " ";
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      //margin-top: -20px;
      //margin-left: 20px;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      border: 6px solid;
      border-color: #367fa9 transparent #367fa9 transparent;
      animation: lds-dual-ring 1.2s linear infinite;
      z-index: 4;
    }
  }
  &.done {
    &:after {
      background-color: rgba(255,255,255, 1);
      content: "Item deleted successful";
    }
  }
}
.coupon_available {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  min-height: 56px;
  position: relative;
  &.processing {
    color: #ccc;
    &:before {
      content: "";
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      margin-top: -20px;
      margin-left: -20px;
      width: 41px;
      height: 41px;
      border-radius: 50%;
      border: 6px solid;
      border-color: #367fa9 transparent #367fa9 transparent;
      animation: lds-dual-ring 1.2s linear infinite;
      z-index: 4;
    }
  }
}
.check-color-button{
  background: transparent;
  width: 40px;
  height: 40px;
  border: none;
  align-items: center;
  justify-content: center;
  transition: 0.3s;
  position: relative;
  padding: 0;
  display: inline-flex;
  &:after{
    content:'';
    position: absolute;
    top: -2px;
    right: -2px;
    bottom: -2px;
    left: -2px;
  }
  &:disabled:hover{
    opacity: 1;
  }
  &.processing {
    svg{
      opacity: 0;
    }
    &:before {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 41px;
      height: 41px;
      border-radius: 50%;
      border: 6px solid;
      border-color: #367fa9 transparent #367fa9 transparent;
      animation: lds-dual-ring 1.2s linear infinite;
      z-index: 4;
    }
  }
  &:hover{
    opacity: 0.7;
  }
}
@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.btn-disabled {
  color: rgba(133, 133, 133, 0.3) !important;
}

.favourites-image-zoom-wrap {
  position: relative;
  opacity: 1 !important;
  img, svg {
    height: 56px;
    width: 56px;
  }
  .favourites-image-zoom-inner {
    opacity: 0;
    visibility: hidden;
    transition: 0.15s;
  }
  &:hover {
    .favourites-image-zoom-inner {
      opacity: 1 !important;
      visibility: visible;
      transition: 0.15s;
      transform: scale(1.5);
    }
  }
}
.favourites-image-zoom-inner {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 120px;
  width: 120px;
  transform: scale(0);
  z-index: 3;
  transition: all 0.3s ease;
  border-radius: 2px;
  img{
    border-radius: 2px;
  }
}
.favourites-image-hidden{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.checkbox__favorite {
  height: 20px;
  width: 20px;
  padding: 1px;
  border: 1px solid #838383;
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  p {
    display: none;
  }
  svg {
    pointer-events: none;
  }
  &-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    .btn {
      margin-bottom: 5px;
    }
  }
  &--disabled {
    border-color: lighten(#ccc, 10%);
    cursor: not-allowed;
    pointer-events: none;
  }
  &--checked {
    border-color: #83CD1B;
  }
  &--unchecked {
    border-color: #D21C10;
  }
}
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .modal-default {
    min-width: 80%;
  }
}
.completedButton {
  display: flex;
  justify-content: center;
  .btn {

  }
}
.similarPriceCell {
  display: flex;
  align-items: center;
  justify-content: space-between;
  &__text {
    width: 100%;
    text-align: left;
  }
  button{
    flex-shrink: 0;
    background: transparent !important;
    border-color: transparent;
    margin-right: 0;
  }
}
.cellLoading {
  position: relative;
  cursor: default;
  &:after {
    content: "";
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 40px;
    height: 40px;
    margin-top: -20px;
    margin-left: -20px;
    border-radius: 50%;
    border: 6px solid;
    border-color: #367fa9 transparent #367fa9 transparent;
    animation: lds-dual-ring 1.2s linear infinite;
    z-index: 4;
  }
  &:before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(#fff, 0.5);
  }
}
.active-row {
  position: relative;
  td {
    position: relative;
  }
  td:first-child {
    &:after {
      content: "";
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      width: 5px;
      background: #3c8dbc;
      z-index: 5;
      opacity: 1 !important;
    }
  }
  td:last-child {
    &:before {
      content: "";
      display: block;
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      width: 5px;
      background: #3c8dbc;
      z-index: 5;
      opacity: 1 !important;
    }
  }
}

.products-list-table-row {
  position: relative;
  .box-header {
    position: absolute;
    top: 0;
    right: 30px;
    .box-tools {
      > div {
        > button.btn {
          right: 20px;
        }
      }
    }
  }
  > .col-md-12 {
    padding-right: 0;
  }
}
.products-list-table {
  .dataTables_wrapper {
    overflow-x: auto;
    margin-bottom: 14px;
  }
  thead tr {
    th img {
      pointer-events: auto;
    }
    th:nth-child(7),
    th:nth-child(8),
    th:nth-child(13),
    th:nth-child(14),
    th:nth-child(15),
    th:nth-child(18),
    th:nth-child(19) {
      pointer-events: none;
      &:after {
        display: none;
      }
    }
  }
  &.orderDisabled {
    thead tr th {
      pointer-events: none;
      &:after {
        display: none;
      }
    }
  }
  tbody {
    tr:has(.viewedRow) {
      background: rgba(#bb8dd9, 0.2);
    }
    tr.viewedRow {
      background: rgba(#bb8dd9, 0.2);
    }
  }
}
.select2-container--focus{
  span[tabindex="0"]{
    border-color: #3c8dbc;
  }
}
.initial-researcher-product{

  .form-group.has-error, .form-group.has-number{
    margin-bottom: 0;
  }
  .help-block{
    position: absolute;
  }
}
.isSuspicious {
  color: darkorange;
  svg {
    path {
      fill: darkorange;
    }
  }
}
.isInactive {
  color: red;
  svg {
    path {
      fill: red;
    }
  }
}
.products-matched-similar{
  .dataTables_wrapper{
    overflow-x: auto;
  }
  table{
    thead{
      th{
        &:nth-of-type(3),
        &:nth-of-type(4){
          pointer-events: none;
          text-align: center !important;
          padding-right: 5px !important;
          &:after {
            display: none;
          }
        }
        &:nth-of-type(6){
          text-align: center;
        }
        @media(any-hover: hover){
          transition: 0.15s;
          &:hover{
            opacity: 0.5;
          }
        }
      }
    }
    tbody{
      tr {
        &:has(.discarded) {
          td {
            opacity: 0.6;
            background-color: rgba(palevioletred, 0.3) !important;
            &:nth-child(3) {
              &:hover {
                opacity: 1;
                background-color: rgba(palevioletred, 0.2) !important;
              }
            }
          }
          button {
            pointer-events: none;
          }
        }
      }
      td{
        p{
          max-width: none;
        }
        max-width: 250px;
        &:nth-of-type(4){
          text-align: center;
        }
        &:nth-of-type(8){
          button{
            width: auto !important;
          }
        }
      }
    }
  }
}
.on-click-edit-product.disabled {
  pointer-events: none;
}
.createProductWrapper {
  padding-bottom: 24px;
  .help-block {
    position: absolute;
    left: 0;
    margin: 0 !important;
  }
  > div > div {
    > .help-block {
      bottom: -22px !important;
    }
  }
  .form-group {
    margin-bottom: 24px;
  }
}
.nonSortImg {
  pointer-events: auto;
  cursor: default;
  &:hover {
    opacity: 1;
  }
}
th:has(.nonSortImg) {
  opacity: 1;
  &:hover {
    opacity: 1 !important;
  }
}
.maxWidth250 {
  max-width: 250px !important;
}

.sidebar-collapse {
  .stickyProductBar {
    padding-left: 86px !important;
  }
}
.modal-dialog {
  &:has(.columns-modal) {
    max-width: 400px;
    width: 100%;
    min-width: auto;
  }
}
.productImageValidationError {
  &+.favourites-image-zoom-inner {
    display: none;
  }
}
